import React, {useEffect}from "react"
import HttpsRedirect from 'react-https-redirect';
import IndexPage2 from './index2.js'
import "./index.scss";
import 'animate.css';
import 'aos/dist/aos.css'; // You can also use <link> for styles


// markup
const IndexPage = () => {
  
  return (
    //   <HttpsRedirect>
          <IndexPage2 /> 
    //   </HttpsRedirect>
  )
}

export default IndexPage
